\<template>
    <div>
        <CSTabBar :tabbar="tabObj" :checked-tab="checkedTab" @changeTabBar="changeTabBar"></CSTabBar>

        <div class="result-panel" ref="resultPanel">
            <div class="content">
                <div v-show="checkedTab === 1" class="live-player-wrap" ref="livePlayerWrap">
                    <div class="cs-player">
                        <div v-show="playerUrl" id="player-con"></div>
<!--                        <video v-show="playerUrl" id="player-con"></video>-->
                        <div class="no-source" v-if="playerUrl === ''">
                            <img src="../../assets/player/no-player.png" alt="">
                            暂无任何直播
                        </div>

                        <div class="full-screen-bar" v-if="!isFullscreen && playerUrl !== ''">
                            <svg class="icon" aria-hidden="true" @click="fullScreen">
                                <use xlink:href="#icon-menua-jianqu1"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="qrcode">
                        <div class="title">直播二维码</div>
                        <div class="content">
                            <div v-if="qrcodeUrl === ''" style="line-height: 150px;text-align: center;">
                                暂无二维码
                            </div>
                            <img v-else :src="qrcodeUrl" alt="">
                        </div>

                        <div class="tips">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            <span>按F9退出全局播放。</span>
                        </div>
                        <button class="btn btn-primary" style="width: 120px; margin: 20px auto; display: block; font-size: 16px;" @click="refreshLive">刷新</button>
                    </div>
                </div>
                <div v-if="checkedTab === 2" class="live-src-config">
                    <p>
                        <span>原画播放地址</span>
                        <input v-model="liveUrl" type="text" class="cs-input" placeholder="请输入">
                    </p>
                    <p>
                        <span>flv播放地址</span>
                        <input v-model="flvUrl" type="text" class="cs-input" placeholder="请输入">
                    </p>
                    <p>
                        <span>m3u8播放地址</span>
                        <input v-model="m3u8Url" type="text" class="cs-input" placeholder="请输入">
                    </p>
                    <p>
                        <span>小程序播放地址</span>
                        <input v-model="rtmpUrl" type="text" class="cs-input" placeholder="请输入">
                    </p>
                    <p>
                        <span></span>
                        <button class="btn btn-primary" @click="saveLiveUrl">保存</button>
                    </p>
                </div>
            </div>
            <div ref="customScroll" class="custom-scroll">
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import {commonImgOssDomain, queryLiveUrlByRegionCodeUrl, saveOrModifyLiveUrl} from "@/requestUrl";

export default {
    name: "LivePlayer",
    components: {CSTabBar},
    data() {
        return {
            tabObj: {
                1: '全局播放',
                2: '视频直播设置'
            },
            checkedTab: 1,
            liveUrl: '',
            rtmpUrl: '',
            playerUrl: '',
            qrcodeUrl: '',
            flvUrl: '',
            m3u8Url: '',
            isFullscreen: false,
        }
    },
    mounted(){
      this.getLiveUrl();
      this.fullScreenListener();
        document.addEventListener("fullscreenchange", () => {
            if (document.fullscreenElement) {
                this.isFullscreen = true;
            } else {
                setTimeout(() => {
                    this.isFullscreen = false;
                }, 100);
            }
        });
        this.initScrollBar();
        window.addEventListener('resize', () => {
            this.initScrollBar();
        })
    },
    methods: {
        initScrollBar() {
            const {customScroll, livePlayerWrap, resultPanel} = this.$refs;
            if (livePlayerWrap.offsetWidth > resultPanel.offsetWidth) {
                customScroll.style.display = 'block';
                customScroll.style.width = resultPanel.offsetWidth + 'px';
                customScroll.getElementsByTagName('div')[0].style.width = livePlayerWrap.offsetWidth + 'px';

                function bindScroll(e) {
                    const els = [customScroll, resultPanel.getElementsByClassName('content')[0]];
                    const {scrollLeft} = e.target;
                    els.splice(els.indexOf(e.target), 1);
                    els.forEach(el => {
                        el.scrollTo(scrollLeft, 0);
                    })
                }
                try {
                    customScroll.removeEventListener('scroll', bindScroll);
                    resultPanel.getElementsByClassName('content')[0].removeEventListener('scroll', bindScroll);
                } catch(err) {
                    console.log(err);
                }
                customScroll.addEventListener('scroll', bindScroll);
                resultPanel.getElementsByClassName('content')[0].addEventListener('scroll', bindScroll);
            } else {
                customScroll.style.display = 'none';
            }
        },
        changeTabBar(id) {
            this.checkedTab = id;
        },
        refreshLive() {
            this.getLiveUrl();
        },
        fullScreenListener() {
            const fullViewEl = document.getElementsByClassName("cs-player")[0];
            window.addEventListener("keydown", (e) => {
                if (e.key === "F9") {
                    if (!document.fullscreenElement) {
                        fullViewEl.requestFullscreen();
                    } else {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        }
                    }
                    e.preventDefault();
                }
            });
            // this.isFullscreen = true;
        },
        fullScreen() {
            const fullViewEl = document.querySelector("#player-con");
            fullViewEl.requestFullscreen();
            this.isFullscreen = true;
        },
        saveLiveUrl() {
            this.$fly.post(saveOrModifyLiveUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                liveUrl: this.liveUrl,
                rtmpUrl: this.rtmpUrl,
                m3u8Url: this.m3u8Url,
                flvUrl: this.flvUrl,
            })
            .then(res => {
                if (res.code === 0) {
                    this.$vc.toast('保存成功')
                }
            })
        },
        getLiveUrl() {
            this.$fly.get(queryLiveUrlByRegionCodeUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
            })
            .then(async res => {
                if (res.code === 0) {
                    const {liveUrl, qrcodeUrl, rtmpUrl, flvUrl, m3u8Url} = res.data;
                    this.liveUrl = liveUrl;
                    this.m3u8Url = m3u8Url;
                    this.flvUrl = flvUrl;
                    this.qrcodeUrl = qrcodeUrl ? commonImgOssDomain + qrcodeUrl : '';
                    this.rtmpUrl = rtmpUrl;
                    this.playerUrl = flvUrl;

                    new Aliplayer({
                            id: "player-con",
                            source: this.playerUrl,
                            width: "100%",
                            height: "500px",
                            autoplay: true,
                            isLive: true,
                            rePlay: false,
                            playsinline: true,
                            preload: true,
                            controlBarVisibility: "never",
                            useH5Prism: true,
                            skinLayout: [
                                {name: "bigPlayButton", align: "cc",},
                            ],
                        }, function (player) {
                            // player.play();
                            console.log(player.getStatus(), '播放状态');
                        }
                    );
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
    .result-panel
        &> .content
            overflow-y auto
            scrollbar-width none
            -ms-overflow-style none
            &::-webkit-scrollbar
                display none
        .custom-scroll
            position sticky
            bottom 0
            left 0
            overflow-y auto
            div
                height 1px
    .live-player-wrap
        padding 30px
        min-width 1220px
        min-height 800px
        & > div
            display inline-block
            vertical-align top
            &.cs-player
                width 960px
                height 540px
                background #000
                margin-right 30px
                position relative
                &:hover
                    .full-screen-bar
                        display block
                video
                    width 100%
                    height 100%
                .no-source
                    position absolute
                    top 50%
                    left 50%
                    transform translate(-50%, -50%)
                    color #fff
                    font-size 24px
                    img
                        width 153px
                        margin-bottom 20px
                        display block
                .full-screen-bar
                    position absolute
                    width 100%
                    display none
                    //height 50px
                    background rgba(0, 0, 0, .6)
                    text-align right
                    padding 10px
                    color #fff
                    bottom 0
                    left 0
                    font-size 30px
                    line-height 1
            &.qrcode
                width 150px
                font-size 14px
                .title
                    margin-bottom 5px
                    position relative
                    font-size 24px
                    padding-left 14px
                    &::after
                        position absolute
                        left 0
                        top 50%
                        transform translateY(-50%)
                        content " "
                        background #00B694
                        width 8px
                        height 24px
                .content
                    width 150px
                    height 150px
                    color #fff
                    text-align center
                    background #999999
                    font-size 20px
                    img
                        width 150px
                        height 150px
                        background #fff
                        border 1px solid #ddd
                .tips
                    margin-top 20px
                    line-height 1
                    svg
                        vertical-align middle
                        margin-right 5px
                    span
                        color #999
                        vertical-align middle
    .live-src-config
        padding 15px 30px
        min-height 800px
        p
            margin-bottom 20px
            font-size 14px
            span
                vertical-align middle
                display inline-block
                width 98px
                margin-right 30px
            input
                vertical-align middle
                width 320px
                height 30px
                border 1px solid #999999
                border-radius 3px
                margin-right 20px
            .btn
                height 30px
                line-height 30px
                padding-top 0
                padding-bottom 0
</style>
